import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Message } from 'primeng/api';

/**
 * dispatch this action for global error handler
 * @param error: Error | HttpErrorResponse
 * @param whitelist?: string[] | number[] an array of http response codes and/or error message containments for which this error should not be globally handled
 */
export const globalFailure = createAction(
  '[Global] Failure',
  props<{ error: Error | HttpErrorResponse; whitelist?: (string | number)[]; method?: string; request_id?: string; requestPayload?: any, supressToast?: boolean }>()
);

/**
 * dispatch this action if you need to return an action that does nothing
 * @param error: Error | HttpErrorResponse
 * @param whitelist?: string[] | number[] an array of http response codes and/or error message containments for which this error should not be globally handled
 */
export const noopAction = createAction('[Global] NOOP');

export const toastAction = createAction('[Global] Toast', props<Message>());
export const fullscreenAction = createAction('[Global] Fullscreen', props<{ isFullscreen: boolean, withoutScrolling?: boolean }>());
export const fullscreenExitedAction = createAction('[Global] Fullscreen exited');
export const fullscreenFailedAction = createAction('[Global] Fullscreen failed');
export const fullscreenSuccessAction = createAction('[Global] Fullscreen success');

export const changeLanguageAction = createAction('[Global] change Language', props<{ language: string }>());
